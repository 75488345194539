import React, { Component } from "react"
import ReactDOM from "react-dom"
import { Link } from "gatsby"

// components
import Navigation from "components/Navigation"

//images
import LogoKeytoe from "assets/logo/Keytoe_logo.svg"
import LogoKeytoeSmall from "assets/logo/Keytoe_logo_wit_klein.svg"
import LogoKeytoeZwart from "assets/logo/Keytoe_logo_zwart.svg"
import LogoKeytoeZwartSmall from "assets/logo/Keytoe_logo_zwart_klein.svg"
import LogoK from "assets/logo/Keytoe_kollega.svg"
import LogoS from "assets/logo/Keytoe_schwung.svg"
import LogoY from "assets/logo/Keytoe_y.svg"
import LogoT from "assets/logo/Keytoe_toscani.svg"
import LogoAcademy from "assets/logo/academy.svg"
import LogoDeFilmerij from "assets/logo/de-filmerij.svg"

import SmallLogoK from "assets/logo/Keytoe_Kollega_klein.svg"
import SmallLogoS from "assets/logo/Keytoe_Schwung_klein.svg"
import SmallLogoY from "assets/logo/Keytoe_Y_klein.svg"
import SmallLogoT from "assets/logo/Keytoe_Toscani_klein.svg"
import SmallLogoAcademy from "assets/logo/academy-small.svg"
import SmallLogoDeFilmerij from "assets/logo/de-filmerij-small.svg"

import Logo from "assets/logo/keytoe-k-logo.svg"
import LogoWhite from "assets/logo/keytoe-k-logo-white.svg"
import LogoFull from "assets/logo/keytoe-logo.svg"

import styles from "./header.module.scss"
import { Script } from "vm"

const modalRoot =
  typeof document !== `undefined` ? document.getElementById("body") : null

class Modal extends Component {
  constructor(props) {
    super(props)

    this.el =
      typeof document !== `undefined` ? document.createElement("div") : null
  }

  componentDidMount() {
    modalRoot.appendChild(this.el)
  }

  // removes the div completely after closing the menu
  componentWillUnmount() {
    modalRoot.removeChild(this.el)
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el)
  }
}
class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: false,
      scrollPrevPos: 0,
      top: "0",
      transform: `translateY(0px)`,
      width: null,
    }
    this.modal = React.createRef()
  }

  componentWillReceiveProps = nextProp => {
    if (nextProp.scrollbar !== this.props.scrollbar) {
      if (nextProp.scrollbar) {
        nextProp.scrollbar.addListener(status => {
          var scrollPos = status.offset.y
          if (this.state.scrollPrevPos > scrollPos) {
            this.setState({
              top: "0",
              transform: `translateY(${scrollPos}px)`,
            })
          } else {
            this.setState({
              top: "-140px",
              transform: `translateY(${scrollPos}px)`,
            })
          }
          this.setState({
            scrollPrevPos: scrollPos,
          })
        })
        nextProp.scrollbar.setPosition(0, 0)
      }
    }
  }
  componentDidMount = () => {
    //header appear&dissapear on scroll
    var scrollPrevPos = window.pageYOffset
    var container = document.getElementById("container")
    document.addEventListener("scroll", function() {
      var scrollPos = window.pageYOffset
      if (window.pageYOffset <= 0) {
        container.style.top = "0"
      } else if (scrollPrevPos > scrollPos) {
        container.style.top = "0"
      } else {
        container.style.top = "-140px"
      }
      scrollPrevPos = scrollPos
    })
    this.calculateSize()
    if (typeof window !== `undefined`) {
      window.addEventListener("resize", this.calculateSize)
    }
  }
  componentWillUnmount = () => {
    if (this.props.scrollbar) {
      this.props.scrollbar.setPosition(0, 0)
    }
    if (typeof window !== `undefined`) {
      window.removeEventListener("resize", this.calculateSize)
    }
  }
  handleClick = () => {
    !this.state.menu
      ? this.setState({
          menu: true,
        })
      : this.setState({
          menu: false,
        })

    const el =
      typeof document !== `undefined`
        ? document.getElementById("gatsby-focus-wrapper")
        : null

    this.state.menu
      ? el.classList.add("menu-open") //switched these two around, header scrolls when menu is opened and closed on same page
      : el.classList.remove("menu-open")
  }

  calculateSize = () => {
    if (typeof window !== `undefined`) {
      this.setState({
        width: window.innerWidth,
      })
    }
  }

  render() {
    const { type, navigation, location } = this.props
    const { top, transform, width } = this.state

    return (
      <>
        {/* if menu shown, show this */}
        <header
          style={{ top: top, transform: transform }}
          id="container"
          ref="container"
          className={[styles.container, type ? styles[type] : null].join(" ")}
        >
          <div className={[styles.wrapper, "wrapper"].join(" ")}>
            {/* use correct keytoe URL, temp solution */}
            <div onClick={this.handleClick} className={styles.logo}>
              {width <= 1024 ? ( //makes big logo very small on exactly 1024
                location.pathname.includes("/k") ? (
                  <SmallLogoK />
                ) : location.pathname.includes("/y") ? (
                  <SmallLogoY />
                ) : location.pathname.includes("/schwung") ? (
                  <SmallLogoS />
                ) : location.pathname.includes("/toen-nu") ? (
                  <LogoKeytoeSmall />
                ) : location.pathname.includes("/t/") ? (
                  <SmallLogoT />
                ) : location.pathname.includes("/meetup") ? (
                  <LogoKeytoeZwartSmall />
                ) : location.pathname.includes("/academy") ? (
                  <SmallLogoAcademy />
                  ) : location.pathname.includes("/de-filmerij") ? (
                  <SmallLogoDeFilmerij />
                ) : (
                  <LogoKeytoeSmall />
                )
              ) : location.pathname.includes("/k") ? (
                <LogoK />
              ) : location.pathname.includes("/y") ? (
                <LogoY />
              ) : location.pathname.includes("/schwung") ? (
                <LogoS />
              ) : location.pathname.includes("/toen-nu") ? (
                <LogoKeytoe />
              ) : location.pathname.includes("/meetup") ? (
                <LogoKeytoeZwart />
              ) : location.pathname.includes("/t/") ? (
                <LogoT />
              ) : location.pathname.includes("/academy") ? (
                <LogoAcademy />
              ) : location.pathname.includes("/de-filmerij") ? (
                <LogoDeFilmerij />
              ) : (
                <LogoKeytoe />
              )}
            </div>
            {navigation && (
              <span className={styles.menu} onClick={this.handleClick}>
                MENU
              </span>
            )}
          </div>
        </header>
        {this.state.menu && (
          <Modal ref={this.modal}>
            <Navigation close={this.handleClick} />
          </Modal>
        )}
      </>
    )
  }
}

export default Header
