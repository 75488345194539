import React, { Component } from "react"
import Scrollbar from "smooth-scrollbar"
import OverscrollPlugin from "smooth-scrollbar/plugins/overscroll"

// components
import Header from "components/Header"
import Footer from "components/Footer"

// styles
import styles from "./layout.module.scss"

class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {
     scrollbar: null
    }
  }
  componentDidMount() {
    this.calculateSize()
    if (typeof window !== `undefined`) {
      window.addEventListener("resize", this.calculateSize)
    }
    // if (typeof document !== `undefined`) {
    //   if (this.props.background === "blue") {
    //     document.body.style.background = "#256eff"
    //   } else if (this.props.background === "white") {
    //     document.body.style.background = "#fff"
    //   } else {
    //     document.body.style.background = "#000"
    //   }
    // }
  }

  componentWillUnmount() {
    if (typeof window !== `undefined`) {
      window.removeEventListener("resize", this.calculateSize)
    }
  }

  calculateSize = () => {
    if (typeof window !== `undefined`) {
      // console.log("window.innerWidth", window.innerWidth)

      if (window.innerWidth >= 1024) {
        if (!this.state.scrollbar) {
          Scrollbar.use(OverscrollPlugin)
          let scrollbar = Scrollbar.init(document.body, {
            // alwaysShowTracks: true,
            damping: 0.15,
            plugins: {
              overscroll: {},
            },
          })
          this.setState({
            scrollbar: scrollbar
          })
        }
      }

    } else {
      Scrollbar.destroy(document.body)
    }
  }

  render() {
    const { children, type, navigation, footer, background, location } = this.props
    return (
      <div
        className={[styles.scrollbar, type ? styles[`${type}`] : null].join(
          " "
        )}
        style={{
          background:
            background === "blue"
              ? "#256eff"
              : background === "white"
              ? "#fff"
              : "#000",
        }}
      >
        <Header type={type} navigation={navigation} scrollbar={this.state.scrollbar} location={location} />
        <main>{children}</main>
        {footer !== false && <Footer type={type} />}
      </div>
    )
  }
}

export default Layout
