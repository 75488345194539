import React, { Component } from "react"
import anime from "animejs/lib/anime.es.js"
import Scrollbar from "smooth-scrollbar"
import { CSSTransition } from "react-transition-group"
import { StaticQuery, graphql } from "gatsby"

import Footer from "components/Footer"

import styles from "./navigation.module.scss"
import NavigationItem from "components/NavigationItem"

import LogoKeytoe from "assets/logo/Keytoe_logo.svg"
import LogoKeytoeSmall from "assets/logo/Keytoe_logo_wit_klein.svg"

class Navigation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      showItems: true,
      transitionBackground: null,
      transitionLogo: null,
      transitionBottom: null,
      width: null,
      showBackground: false,
    }
  }
  componentDidMount() {
    this.calculateSize()
    if (typeof window !== `undefined`) {
      window.addEventListener("resize", this.calculateSize)
    }
    const transitionLogo = anime
      .timeline({
        easing: "easeOutCubic",
        duration: 900,
        autoplay: false,
      })
      .add({
        targets: this.refs.navigation.querySelector(`.${styles.logo}`),
        duration: 600,
        translateY: [-114, 0],
        opacity: [0, 1],
        delay: 0,
        easing: "easeInOutCirc(1, .5)",
      })
     
    const transitionClose = anime
      .timeline({
        easing: "easeOutCubic",
        duration: 900,
        autoplay: false,
      })
      .add({
        targets: this.refs.navigation.querySelector(`.${styles.close}`),
        duration: 300,
        translateY: ["-100%", "0%"],
        opacity: [0, 1],
      })
      const transitionBottom = anime
        .timeline({
          easing: "easeOutCubic",
          duration: 900,
          autoplay: false,
        })
        .add({
          targets: this.refs.navigation.querySelector(`.${styles.bottom1}`),
          duration: 100,
          translateY: [-190, 0],
          opacity: [0, 1],
          delay: 0,
          easing: "easeInOutCirc(1, .5)",
        })


    this.setState({
      showBackground: true,
      transitionLogo: transitionLogo,
      transitionClose: transitionClose,
      transitionBottom: transitionBottom,
    })
    setTimeout(() => {
        Scrollbar.init(document.querySelector(`.${styles.navigation}`), {
        damping: 0.15,
      })
      this.setState({
        show: true,
      })
      transitionBottom.play()
      transitionLogo.play()
      transitionClose.play()
    }, 600)
  }

  componentWillUnmount() {
    if (typeof window !== `undefined`) {
      window.removeEventListener("resize", this.calculateSize)
    }
  }

  calculateSize = () => {
    if (typeof window !== `undefined`) {
      this.setState({
        width: window.innerWidth,
      })
    }
  }

  getImagePosition = () => {
    const positions = [0, 280, 140]
    if (typeof window !== `undefined`) {
      if (window.innerWidth >= 1024) {
        return positions[Math.floor(Math.random() * positions.length)]
      } else {
        return 0
      }
    }
  }

  close = event => {
    event.preventDefault()
    this.setState({
      showItems: false,
    })
    if (!this.state.transitionClose.reversed) {
      this.state.transitionBottom.reverse()
      this.state.transitionBottom.play()
      this.state.transitionClose.reverse()
      this.state.transitionClose.play()
      this.state.transitionLogo.reverse()
      this.state.transitionLogo.play()
    }
    setTimeout(() => {
      this.setState({ showBackground: false })
    }, 1400)
    setTimeout(() => {
      this.props.close()
    }, 1600)
  }

  render() {
    const { type } = this.props
    const { show, width, showBackground } = this.state
    let timeout = 0
    return (
      <>
        <nav className={styles.navigation} ref="navigation">
          <CSSTransition
            in={showBackground}
            timeout={{
              enter: 100,
              exit: 100,
            }}
            classNames={{
              enterDone: styles.backgroundEnterDone,
              exitDone: styles.backgroundExitDone,
            }}
          >
            <div className={styles.background}></div>
          </CSSTransition>
          <div className={styles.scroll}>
            <header className={styles.header}>
              <div className={styles.logo}>
                {width <= 1024 ? <LogoKeytoeSmall /> : <LogoKeytoe />}
              </div>
            </header>
            <a className={styles.close} onClick={this.close}>
              <span className={styles.sluit}>Sluiten</span>
            </a>

            {show ? (
              <div className={styles.items}>
                <StaticQuery
                  query={navigationQuery}
                  render={data =>
                    data.items.edges.map((item, key) => (
                      <NavigationItem
                        key={key}
                        details={{
                          show: this.state.showItems,
                          delay: 100 * key + timeout,
                          imagePosition: this.getImagePosition(),
                          link: item.node.slug,
                          image: item.node.image,
                          title: item.node.title,
                          pre: item.node.pre,
                          color: item.node.color,
                        }}
                      />
                    ))
                  }
                />
              </div>
            ) : null}
            {show && <Footer type={type} />}
          </div>
          
        </nav>
        <div className={styles.bottom1} />
      </>
    )
  }
}

const navigationQuery = graphql`
  query NavigationItems {
    items: allWordpressNavigation(sort: {fields: wordpress_id}) {
      edges {
        node {
          title
          slug
          pre
          wordpress_id
          color
          image {
            title
            alt_text
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Navigation
